import styled, { css } from 'styled-components';

import reinforcingImage from 'assets/img/reinforcing_statement_background.jpg';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    background-color: ${theme.colors.black};

    ${theme.media.tablet} {
      display: block;
    }

    ${theme.media.mobile} {
      height: 100%;
    }

    .imageBlock {
      width: 100%;
      background: url(${reinforcingImage}) no-repeat;
      background-size: cover;
      background-position: 10%;
    }

    .contentReinforcingStatement {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: ${theme.space[6]};

      ${theme.media.tablet} {
        ${theme.utils.px(theme.space[8])};
      }

      ${theme.media.mobile} {
        ${theme.utils.py(theme.space[8])};
      }

      strong {
        width: 650px;

        font-size: ${theme.fontSizes['7xl']};

        color: ${theme.colors.blue};

        ${theme.media.laptop} {
          width: 450px;
          font-size: ${theme.fontSizes['5xl']};
        }

        ${theme.media.tablet} {
          width: 100%;
        }

        ${theme.media.tablet} {
          font-size: ${theme.fontSizes['3xl']};
        }
      }

      p {
        width: 650px;

        font-size: ${theme.fontSizes.lg};
        line-height: ${theme.lineHeights.xl};

        color: ${theme.colors.white};

        ${theme.media.laptop} {
          width: 450px;

          font-size: ${theme.fontSizes.md};
        }

        ${theme.media.tablet} {
          width: 100%;
        }
      }
    }
  `}
`;
