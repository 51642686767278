import styled, { css } from 'styled-components';
import { SolutionCardProps } from './';

type ContentProps = Omit<SolutionCardProps, 'title' | 'image' | 'text'>;

const cardModifiers = {
  left: () => css`
    display: flex;
    justify-content: flex-end;

    ${Content} {
      left: 0;

      border-radius: 0 2rem 0 2rem;

      &:before {
        left: 1rem;

        border-radius: 0 2rem 0 2rem;

        background: linear-gradient(-228deg, #0068ff, #3de493);
      }
    }
  `
};

export const Wrapper = styled.div<ContentProps>`
  ${({ theme, direction }) => css`
    width: 100%;
    position: relative;

    ${direction === 'left' && cardModifiers.left};

    img {
      width: 65%;

      border-radius: ${theme.rounded['2xl']};
    }
  `}
`;

export const Content = styled.div<ContentProps>`
  ${({ theme }) => css`
    max-width: 676px;
    min-height: 450px;
    ${theme.utils.px(theme.space[20])}
    ${theme.utils.py(theme.space[16])}
    position: absolute;
    right: 3%;
    bottom: -5%;

    border-radius: 2rem 0 2rem 0;

    background-color: ${theme.colors.white};

    z-index: 1;

    ${theme.media.laptop} {
      max-width: 476px;
      right: -5%;
      bottom: -35%;
    }

    &:before {
      content: '';
      width: 100%;
      height: calc(100% + 1rem);
      position: absolute;
      top: -1rem;
      left: -1rem;

      border-radius: 2rem 0 2rem 0;

      background: linear-gradient(228deg, #0068ff, #3de493);

      z-index: -1;
    }

    &:after {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      border-radius: inherit;
    }

    h1 {
      font-size: ${theme.fontSizes['7xl']};

      color: ${theme.colors.black};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['5xl']};
      }
    }

    p {
      margin-top: ${theme.space[4]};

      font-size: ${theme.fontSizes.lg};
      line-height: ${theme.lineHeights.xl};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes.md};
      }
    }
  `}
`;
