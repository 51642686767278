import styled, { css } from 'styled-components';
import { HeaderProps } from './';

export const Header = styled.header<HeaderProps>`
  ${({ theme, full }) => css`
    ${theme.utils.px(`${theme.space[16]}`)}
    ${theme.utils.py(`${theme.space[8]}`)}

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${full &&
    css`
      background-color: ${theme.colors.black};
    `}

    ${theme.media.tablet} {
      ${theme.utils.px(`${theme.space[8]}`)}
    }

    img {
      width: 21rem;

      ${theme.media.mobile} {
        width: 4rem;
      }
    }

    button {
      display: flex;

      color: ${theme.colors.white};

      transition: opacity 0.2s;

      &:hover {
        opacity: ${theme.opacity[80]};
      }
    }
  `}
`;
