import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    ${theme.utils.px(theme.space[24])}
    ${theme.utils.py(theme.space[16])}

    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.blue};

    ${theme.media.tablet} {
      height: 100%;
      ${theme.utils.px(theme.space[8])}
      ${theme.utils.py(theme.space[16])}
    }

    > h2 {
      font-size: ${theme.fontSizes['6xl']};

      color: ${theme.colors.white};

      ${theme.media.tablet} {
        font-size: ${theme.fontSizes['4xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['2xl']};
      }
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.space[20]};

    ${theme.media.tablet} {
      margin-top: ${theme.space[8]};

      flex-direction: column;
      gap: ${theme.space[8]};
    }

    .cardLink {
      width: 300px;

      ${theme.media.laptop} {
        width: 200px;
      }

      span {
        margin: 0 auto;
        margin-top: ${theme.space[4]};

        display: block;

        font-size: ${theme.fontSizes['2xl']};
        text-align: center;
        text-transform: uppercase;

        color: ${theme.colors.white};

        ${theme.media.laptop} {
          font-size: ${theme.fontSizes.xl};
        }

        ${theme.media.tablet} {
          font-size: ${theme.fontSizes.md};
        }
      }
    }
  `}
`;

export const CardImage = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space[4]};

    border: ${theme.border[2]} solid ${theme.colors.green};
    border-radius: 3rem;

    .imageBlock {
      background-color: ${theme.colors.white};

      border-radius: 3rem;

      img {
        width: 100%;
      }
    }
  `}
`;

export const ButtonBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;

    ${theme.media.tablet} {
      margin-top: ${theme.space[8]};
    }
  `}
`;
