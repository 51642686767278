import { Wrapper } from './styles';

export function ListAboutSection() {
  return (
    <Wrapper>
      <div className="textBlock">
        <strong>Afinal, somos especialistas em desenvolvimento</strong>
      </div>
      <div className="listBlock">
        <div className="listContent">
          <span>{'>'} de pessoas</span>
          <span>{'>'} de profissionais</span>
          <span>{'>'} de softwares</span>
          <span>{'>'} tecnológico</span>
          <span>{'>'} e muito mais</span>
        </div>
      </div>
    </Wrapper>
  );
}
