import styled, { css } from 'styled-components';

import backgroundImage from 'assets/img/home_asset.svg';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: relative;

    display: flex;
    flex-direction: column;

    background: ${theme.colors.gradients.blueToBlack};

    ${theme.media.mobile} {
      height: 100%;
    }
  `}
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  background: url(${backgroundImage}) no-repeat;
  background-position-x: 90%;

  pointer-events: none;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    ${theme.utils.px(`${theme.space[24]}`)}
    ${theme.utils.py(`${theme.space[8]}`)}

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])};
    }

    flex: 1;

    h1 {
      max-width: 900px;

      font-size: ${theme.fontSizes['7xl']};

      color: ${theme.colors.white};

      ${theme.media.laptop} {
        max-width: 600px;

        font-size: ${theme.fontSizes['5xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['2xl']};
      }
    }
  `}
`;
