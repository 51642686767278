import styled, { css } from 'styled-components';
import TextTransition from 'react-text-transition';

export const Text = styled(TextTransition)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes['8xl']};

    color: ${theme.colors.green};

    ${theme.media.laptop} {
      font-size: ${theme.fontSizes['6xl']};
    }

    ${theme.media.mobile} {
      font-size: ${theme.fontSizes['4xl']};
    }
  `}
`;
