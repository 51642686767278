import { useMediaQuery } from 'react-responsive';

import { Wrapper } from './styles';

export function ReinforcingStatementSection() {
  const tabletQuery = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Wrapper>
      {!tabletQuery && <div className="imageBlock" />}
      <div className="contentReinforcingStatement">
        <strong>Uma empresa que tem co-responsabilidade</strong>
        <p>
          por tudo o que faz, para entregar ao mercado, mão de obra
          especializada, inteligência sistêmica, tecnologia de ponta e inovação
          na arquitetura de informação e no desenvolvimento de softwares
          fullstack.
        </p>
      </div>
    </Wrapper>
  );
}
