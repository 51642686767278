import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { HashLink } from 'react-router-hash-link';

export const Wrapper = styled(animated.div)`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;

    z-index: 10;

    background-color: ${theme.colors.black};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    ${theme.utils.px(theme.space[4])};
    position: relative;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${theme.media.tablet} {
      display: flex;
    }

    .linksContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      ${theme.media.tablet} {
        .linksListContainer {
          width: 100%;
        }
      }

      a {
        display: block;
      }
    }

    .coloredLogoImageContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20rem;
      }
    }
  `}
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.space[16]};
    right: ${theme.space[16]};

    color: ${theme.colors.blue};

    ${theme.media.tablet} {
      top: ${theme.space[16]};
      right: ${theme.space[9]};
    }

    ${theme.media.mobile} {
      top: ${theme.space[8]};
    }

    &:hover {
      opacity: ${theme.opacity[80]};
    }
  `}
`;

export const ButtonHashLink = styled(HashLink)`
  ${({ theme }) => css`
    padding-right: ${theme.space[8]};
    padding-bottom: ${theme.space[6]};

    font-size: ${theme.fontSizes['4xl']};
    font-weight: ${theme.fontWeights.light};
    text-transform: uppercase;

    border-bottom ${theme.border[1]} solid ${theme.colors.white};

    color: ${theme.colors.white};

    transition: border, color, 0.2s;

    & + a {
      margin-top: ${theme.space[8]};
    }

    &:hover {
      border-bottom ${theme.border[1]} solid  ${theme.colors.blue};

      color: ${theme.colors.blue};
    }

    ${theme.media.tablet} {
      font-size: ${theme.fontSizes['2xl']};
    }
  `}
`;
