import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { MenuProvider } from 'context/MenuContext';

import { RouterPage } from './routes';

import GlobalStyles from 'styles/global';
import theme from 'styles/theme';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MenuProvider>
          <RouterPage />
        </MenuProvider>
        <GlobalStyles />
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover={false}
        />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
