import { Wrapper, TextBlock } from './styles';

export interface CardProps {
  number: string;
  text: string;
  title: string;
}

export function Card({ number, text, title }: CardProps) {
  return (
    <Wrapper gridArea={number}>
      <div className="titleContent">
        <h2>{number}</h2>
        <strong>{title}</strong>
      </div>
      <TextBlock>
        <p>{text}</p>
      </TextBlock>
    </Wrapper>
  );
}
