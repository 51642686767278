import { useNavigate } from 'react-router-dom';
import format from 'date-fns/formatDistanceToNowStrict';
import { ptBR } from 'date-fns/locale';

import { Button } from 'components';

import wansoftVideo from 'assets/video/wansoft.mp4';

import { Wrapper, TextContent } from './styles';

export function AboutSection() {
  const navigate = useNavigate();

  const dateYear = format(new Date(2004, 9, 30), {
    locale: ptBR,
    roundingMethod: 'floor'
  });

  return (
    <Wrapper>
      <video src={wansoftVideo} muted loop autoPlay />
      <TextContent>
        <div className="aboutContent">
          <h1>Wansoft</h1>
          <h2>
            Quem <br /> somos ?
          </h2>
          <p>
            O HUB de tecnologia da informação, que há {dateYear} se dedica a
            impulsionar o desenvolvimento, valorizando as pessoas nossa maior
            força , a inteligência remota e a comunicação ágil, clara e direta.
          </p>
          <Button variant="primary" onClick={() => navigate('/about')}>
            Conheça mais
          </Button>
        </div>
      </TextContent>
    </Wrapper>
  );
}
