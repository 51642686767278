import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    label {
      display: inline-flex;
      align-items: center;
      gap: ${theme.space[2]};

      cursor: pointer;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      span {
        font-size: ${theme.fontSizes.lg};

        color: ${theme.colors.white};
      }
    }

    input {
      display: none;
    }
  `}
`;
