import * as Yup from 'yup';

import { getValidationErrors } from './helpers/getValidationErrors';

export interface RegisterYupValues {
  [key: string]: string;
}

export async function RegisterYup(values: RegisterYupValues) {
  try {
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().required(),
      phone: Yup.string().required(),
      linkedin: Yup.string().required(),
      message: Yup.string().required()
    });

    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return getValidationErrors(error);
    }
  }
}
