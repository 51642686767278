import handClickIcon from 'assets/img/handClickIcon.png';
import routeIcon from 'assets/img/routesIcon.png';
import circleIcon from 'assets/img/circlesIcon.png';

export default [
  {
    text: 'Consultoria Estratégica',
    image: routeIcon,
    href: '/solutions#consult'
  },
  {
    text: 'Soluções customizadas',
    image: handClickIcon,
    href: '/solutions#customizations'
  },
  {
    text: 'Sustentação',
    image: circleIcon,
    href: '/solutions#sustenance'
  }
];
