import { useState, ChangeEvent, InputHTMLAttributes } from 'react';
import { Upload } from 'phosphor-react';

import { Wrapper } from './styles';

export type UploadFieldProps = InputHTMLAttributes<HTMLInputElement>;

export function UploadField({ onChange, ...props }: UploadFieldProps) {
  const [fileName, setFileName] = useState('');

  function handleChangeFile(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];

    if (!file) return;

    const { name } = file;

    setFileName(name);
  }

  return (
    <Wrapper>
      <label htmlFor="uploadFileInput">
        <Upload size={32} />
        <span>{fileName || 'Envie seu documento'}</span>
      </label>
      <input
        type="file"
        id="uploadFileInput"
        onChange={(event) => {
          if (onChange) onChange(event);
          handleChangeFile(event);
        }}
        {...props}
      />
    </Wrapper>
  );
}
