import styled, { css, DefaultTheme } from 'styled-components';
import { transparentize } from 'polished';

import { ButtonProps } from '.';

const buttonModifiers = {
  primary: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.blue};
    color: ${theme.colors.white};

    transition: all 0.2s;

    &:hover {
      box-shadow: 0 0 0.75rem ${transparentize(0.2, theme.colors.blue)};
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.green};
    color: ${theme.colors.blue};

    transition: all 0.2s;

    &:hover {
      box-shadow: 0 0 0.75rem ${transparentize(0.2, theme.colors.green)};
    }
  `,
  black: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};

    transition: all 0.2s;

    &:hover {
      box-shadow: 0 0 0.75rem ${transparentize(0.2, theme.colors.black)};
    }
  `
};

export const Button = styled.button<ButtonProps>`
  ${({ theme, variant }) => css`
    ${theme.utils.px(theme.space[12])};
    ${theme.utils.py(theme.space[4])};

    font-size: ${theme.fontSizes.lg};

    border-radius: ${theme.rounded.lg};

    ${theme.media.laptop} {
      ${theme.utils.px(theme.space[7])};
      font-size: ${theme.fontSizes.md};
    }

    ${variant && buttonModifiers[variant](theme)};
  `}
`;
