import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;

    display: flex;
    gap: ${theme.space[40]};

    ${theme.media.laptop} {
      gap: ${theme.space[12]};
    }

    ${theme.media.tablet} {
      flex-direction: column;
      gap: 0;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;

      ${theme.media.tablet} {
        max-height: 320px;
      }
    }
  `}
`;

export const TextContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])};
    }

    .aboutContent {
      max-width: 460px;

      ${theme.media.tablet} {
        max-width: 100%;
      }
    }

    h1 {
      font-size: ${theme.fontSizes['8xl']};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['6xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['4xl']};
      }

      color: ${theme.colors.blue};
    }

    h2 {
      ${theme.utils.my(theme.space[6])}
      font-size: ${theme.fontSizes['6xl']};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['4xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['2xl']};
      }
    }

    p {
      font-size: ${theme.fontSizes.lg};
      font-weight: ${theme.fontWeights.light};
      line-height: ${theme.lineHeights['3xl']};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes.md};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes.sm};
        line-height: ${theme.lineHeights.xl};
      }
    }

    button {
      margin-top: ${theme.space[12]};
    }
  `}
`;
