import { Footer } from 'components';

import {
  AboutSection,
  ListAboutSection,
  ReinforcingStatementSection
} from './layout';

import { Wrapper } from './styles';

export const About = () => {
  return (
    <Wrapper>
      <AboutSection />
      <ReinforcingStatementSection />
      <ListAboutSection />
      <Footer />
    </Wrapper>
  );
};
