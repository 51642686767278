import { useMediaQuery } from 'react-responsive';
import format from 'date-fns/formatDistanceToNowStrict';
import { ptBR } from 'date-fns/locale';

import { Header } from 'components';

import { TextAnimation } from '../../components';

import { Wrapper, BackgroundImage, Content } from './styles';

export function HomeSection() {
  const notebookQuery = useMediaQuery({ query: '(max-width: 1280px)' });

  const dateYear = format(new Date(2004, 9, 30), {
    locale: ptBR,
    roundingMethod: 'floor'
  });

  return (
    <Wrapper>
      <Header />
      {!notebookQuery && <BackgroundImage />}
      <Content>
        <div className="textBlock">
          <h1>
            Um hub de tecnologia que há {dateYear} se dedica a impulsionar o
            desenvolvimento de
          </h1>
          <TextAnimation />
        </div>
      </Content>
    </Wrapper>
  );
}
