import { useMediaQuery } from 'react-responsive';
import format from 'date-fns/formatDistanceToNowStrict';
import { ptBR } from 'date-fns/locale';

import { Header } from 'components';

import wansoftLogo from 'assets/img/logo_mobile.png';

import { Wrapper, AboutContent } from './styles';

export function AboutSection() {
  const dateYear = format(new Date(2004, 9, 30), {
    locale: ptBR,
    roundingMethod: 'floor'
  });

  const tabletQuery = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Wrapper>
      <Header full />
      <AboutContent>
        <div className="content">
          <h1>Prazer, somos a Wansoft</h1>
          <p>
            Prazer, somos a Wansoft , o HUB de tecnologia da informação, que há{' '}
            {dateYear} se dedica a impulsionar o desenvolvimento, valorizando as
            pessoas nossa maior força , a inteligência remota e a comunicação
            ágil, clara e direta.
          </p>
        </div>
        {!tabletQuery && (
          <div className="imageBlock">
            <img src={wansoftLogo} />
          </div>
        )}
      </AboutContent>
    </Wrapper>
  );
}
