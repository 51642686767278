import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { X } from 'phosphor-react';

import { MenuContext } from 'context/MenuContext';

import coloredLogoImage from 'assets/img/logo_with_colors.png';

import { Wrapper, Content, ButtonHashLink, CloseButton } from './styles';

import links from './links';
import { createPortal } from 'react-dom';

export interface MenuProps {
  transition?: any; //eslint-disable-line
}

export function Menu({ transition }: MenuProps) {
  const { handleChangeMenuStatus } = useContext(MenuContext);

  const tabletQuery = useMediaQuery({ query: '(max-width: 768px)' });

  return createPortal(
    <Wrapper style={transition}>
      <Content>
        <CloseButton onClick={() => handleChangeMenuStatus(false)}>
          <X size={32} weight="bold" />
        </CloseButton>
        <div className="linksContainer">
          <div className="linksListContainer">
            {links.map((item) => (
              <ButtonHashLink
                key={item.id}
                to={item.href}
                onClick={() => handleChangeMenuStatus(false)}
              >
                {item.label}
              </ButtonHashLink>
            ))}
          </div>
        </div>
        {!tabletQuery && (
          <div className="coloredLogoImageContainer">
            <img src={coloredLogoImage} alt="Wansoft" />
          </div>
        )}
      </Content>
    </Wrapper>,
    document.getElementById('menu')!
  );
}
