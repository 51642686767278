import { FormEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { X } from 'phosphor-react';
import emailJS from '@emailjs/browser';

import { Button, Footer, Input, TextArea, UploadField } from 'components';

import { RegisterYup } from 'utils/RegisterYup';

import { Wrapper, Content, CloseButton } from './styles';

import mock from './mock';

export function ProfessionalForm() {
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    linkedin: '',
    message: ''
  });
  const formRef = useRef(null);

  const navigate = useNavigate();

  function handleOnChangeValues(value: string | number, name: string) {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  }

  async function handleSendEmail(event: FormEvent) {
    event.preventDefault();

    const errors = await RegisterYup(values);

    if (errors) {
      return toast.error('Preencha todos os campos do formulário.');
    }

    return emailJS
      .sendForm(
        'service_x5isrnz',
        'template_tdqxkll',
        formRef.current!,
        'iO69GTnKuw1qMNGXg'
      )
      .then(
        (result) => {
          console.log(result);
          toast.success('E-mail enviado com sucesso!');
          setValues({
            name: '',
            email: '',
            phone: '',
            linkedin: '',
            message: ''
          });
        },
        (error) => {
          console.log(error);
          toast.error('Falha no envio do email!');
        }
      );
  }

  return (
    <Wrapper>
      <CloseButton onClick={() => navigate('/')}>
        <X size={32} weight="bold" />
      </CloseButton>
      <Content>
        <h1>Formulário para Profissionais</h1>
        <form ref={formRef} onSubmit={handleSendEmail}>
          {mock.map((field) =>
            field.type === 'input' ? (
              <Input
                name={field.name}
                label={field.label}
                onChange={(event) =>
                  handleOnChangeValues(event.target.value, event.target.name)
                }
                value={values[field.name]}
              />
            ) : (
              <TextArea
                name={field.name}
                label={field.label}
                onChange={(event) =>
                  handleOnChangeValues(event.target.value, event.target.name)
                }
                value={values[field.name]}
              />
            )
          )}
          <div className="uploadBlock">
            <div />
            <UploadField />
          </div>
          <div className="buttonBlock">
            <Button variant="black" type="submit">
              Enviar
            </Button>
          </div>
        </form>
      </Content>
      <Footer />
    </Wrapper>
  );
}
