import { Wrapper, Content } from './styles';

export interface SolutionCardProps {
  direction: 'right' | 'left';
  image: string;
  text: string;
  title: string;
}

export function SolutionCard({
  direction,
  image,
  text,
  title
}: SolutionCardProps) {
  return (
    <Wrapper direction={direction}>
      <img src={image} alt={title} />
      <Content direction={direction}>
        <h1>{title}</h1>
        <p>{text}</p>
      </Content>
    </Wrapper>
  );
}
