export default [
  {
    key: '1',
    label: 'Nome',
    name: 'name',
    type: 'input'
  },
  {
    key: '2',
    label: 'Email',
    name: 'email',
    type: 'input'
  },
  {
    key: '3',
    label: 'Telefone',
    name: 'phone',
    type: 'input'
  },
  {
    key: '4',
    label: 'Linkedin',
    name: 'linkedin',
    type: 'input'
  },
  {
    key: '5',
    label: 'Mensagem',
    name: 'message',
    type: 'textArea'
  }
] as const;
