import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import mobileLogo from 'assets/img/logo_azul.png';
import desktopLogo from 'assets/img/footer_logo.png';

import social from './social';

import { Wrapper, Content, SocialMediaLinksContent } from './styles';

export function Footer() {
  const tabletQuery = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Wrapper>
      <Content>
        <Link to="/">
          <img src={tabletQuery ? mobileLogo : desktopLogo} />
        </Link>
        <SocialMediaLinksContent>
          {social.map((link) => (
            <a key={link.id} href={link.href} className="socialLink">
              {link.icon}
            </a>
          ))}
        </SocialMediaLinksContent>
      </Content>
      <p>Copyright 2021. All Rights Reserved.</p>
      <p>WANSOFT DESENVOLVIMENTO DE SOFTWARE EIRELI</p>
    </Wrapper>
  );
}
