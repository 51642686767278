import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Wrapper, Content, ButtonBlock, CardImage } from './styles';

import mock from './mock';
import { Button } from 'components';

export function SolutionSection() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <h2>Soluções</h2>
      <Content>
        {mock.map((card) => (
          <HashLink key={card.text} to={card.href} className="cardLink">
            <CardImage>
              <div className="imageBlock">
                <img src={card.image} alt={card.text} />
              </div>
            </CardImage>
            <span>{card.text}</span>
          </HashLink>
        ))}
      </Content>
      <ButtonBlock>
        <Button variant="secondary" onClick={() => navigate('/solutions')}>
          Conheça mais
        </Button>
      </ButtonBlock>
    </Wrapper>
  );
}
