import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.footer`
  ${({ theme }) => css`
    width: 100%;
    ${theme.utils.px(theme.space[32])};
    ${theme.utils.py(theme.space[40])};

    background: ${theme.colors.black};

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])};
      ${theme.utils.py(theme.space[16])};
    }

    p {
      margin-top: ${theme.space[6]};

      text-align: right;

      color: ${theme.colors.white};

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes.xs};
      }
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 20rem;

      ${theme.media.tablet} {
        width: 10rem;
      }

      ${theme.media.mobile} {
        width: 5rem;
      }
    }
  `}
`;

export const SocialMediaLinksContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.space[4]};

    .socialLink {
      padding: ${theme.space[2]};

      display: inline-flex;

      border-radius: ${theme.rounded.lg};

      background-color: ${theme.colors.blue};

      transition: all 0.2s;

      &:hover {
        background-color: ${transparentize(0.3, theme.colors.blue)};
      }
    }
  `}
`;
