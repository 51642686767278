import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;

    ${theme.utils.px(theme.space[40])};
    ${theme.utils.py(theme.space[32])};

    background-color: ${theme.colors.blue};

    ${theme.media.laptop} {
      ${theme.utils.px(theme.space[16])};
      ${theme.utils.py(theme.space[32])};
    }

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])};
      ${theme.utils.py(theme.space[24])};
    }

    > h1 {
      font-size: ${theme.fontSizes['7xl']};

      ${theme.media.tablet} {
        font-size: ${theme.fontSizes['5xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['3xl']};
      }
    }

    form {
      margin-top: ${theme.space[8]};

      div + div {
        margin-top: ${theme.space[8]};
      }
    }

    .uploadBlock {
      margin-top: ${theme.space[8]};

      display: grid;
      grid-template-columns: 0.15fr 1fr;

      ${theme.media.tablet} {
        grid-template-columns: 0.3fr 1fr;
      }

      ${theme.media.mobile} {
        display: flex;
      }
    }

    .buttonBlock {
      width: 100%;
      margin-top: ${theme.space[8]};

      display: flex;
      justify-content: flex-end;
    }
  `}
`;

export const CloseButton = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.space[16]};
    right: ${theme.space[16]};

    color: ${theme.colors.white};

    cursor: pointer;

    ${theme.media.tablet} {
      top: ${theme.space[16]};
      right: ${theme.space[9]};
    }

    ${theme.media.mobile} {
      top: ${theme.space[8]};
    }
  `}
`;
