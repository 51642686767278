import styled, { css } from 'styled-components';

interface WrapperStyleProps {
  gridArea: string;
}

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ theme, gridArea }) => css`
    width: 100%;
    max-width: 400px;
    ${theme.utils.py(theme.space[20])};
    ${theme.utils.px(theme.space[4])};

    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${theme.space[2]};
    grid-area: ${`card${gridArea}`};

    border: ${theme.border[1]} solid ${theme.colors.blue};

    background: ${theme.colors.gradients.greenToBlue};
    background-size: 250%;
    background-position: left;

    transition: all 0.2s;

    ${theme.media.tablet} {
      background-size: 400%;
    }

    &:hover {
      background-size: 700%;
      background-position: right;

      color: ${theme.colors.white};

      cursor: pointer;

      ${TextBlock} {
        transform: translateY(-50px);

        ${theme.media.mobile} {
          transform: translateY(-30px);
        }

        p {
          opacity: 1;
        }
      }

      .titleContent {
        transform: translateY(-50px);
      }

      ${theme.media.mobile} {
        .titleContent {
          transform: translateY(-30px);
        }
      }
    }

    .titleContent {
      text-align: center;

      transition: transform 0.2s;
    }

    h2 {
      font-size: ${theme.fontSizes['7xl']};

      color: ${theme.colors.green};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['5xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['3xl']};
      }
    }

    strong {
      font-size: ${theme.fontSizes['3xl']};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes.xl};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes.lg};
      }
    }
  `}
`;

export const TextBlock = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;

    height: 100%;

    transition: transform 0.2s;

    p {
      ${theme.utils.px(theme.space[2])};
      position: absolute;

      font-size: ${theme.fontSizes.lg};
      font-weight: ${theme.fontWeights.light};
      text-align: center;
      line-height: ${theme.lineHeights.lg};

      opacity: 0;
      transition-property: bottom, opacity;
      transition-duration: 0.2s;

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes.md};
      }

      ${theme.media.mobile} {
        width: 100%;

        font-size: ${theme.fontSizes.xs};
        line-height: ${theme.lineHeights.xs};

        text-align: center;
      }
    }
  `}
`;
