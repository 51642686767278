import styled, { css } from 'styled-components';

import background from 'assets/img/globalConnection.jpg';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;

    display: grid;
    grid-template-columns: 1fr 0.5fr;

    background-color: ${theme.colors.green};

    ${theme.media.tablet} {
      display: block;
    }

    ${theme.media.mobile} {
      height: 100%;
      ${theme.utils.py(theme.space[8])};
    }

    .imageContainer {
      width: 100%;
      background-color: ${theme.colors.black};
      background: url(${background}) no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    ${theme.utils.px(theme.space[24])}

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.space[12]};

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])}
    }

    > h2 {
      font-size: ${theme.fontSizes['6xl']};

      color: ${theme.colors.black};

      ${theme.media.tablet} {
        font-size: ${theme.fontSizes['4xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['2xl']};
      }
    }

    p {
      max-width: 750px;

      font-size: ${theme.fontSizes.xl};
      font-weight: ${theme.fontWeights.light};
      line-height: ${theme.lineHeights.xl};

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes.md};
      }
    }
  `}
`;

export const FormLinkBlock = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      display: flex;
      align-items: center;
      gap: ${theme.space[4]};

      font-size: ${theme.fontSizes['3xl']};
      text-decoration: underline;

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes.xl};
      }
    }

    a + a {
      margin-top: ${theme.space[4]};
    }
  `}
`;
