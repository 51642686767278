import { createGlobalStyle, css } from 'styled-components';
import { transparentize, darken } from 'polished';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ${({ theme }) => css`
    html {
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${transparentize(0.6, theme.colors.black)};

        border-radius: ${theme.rounded.full};
      }

      &::-webkit-scrollbar-track {
        background-color: ${darken(0.2, theme.colors.white)};
      }
    }
    body {
      font: ${theme.fontWeights.regular} ${theme.fontSizes.md}
        ${theme.fonts.default};
    }

    button {
      cursor: pointer;

      border: 0;

      background-color: transparent;

      &:disabled {
        cursor: not-allowed;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  `}
`;
