import { ButtonHTMLAttributes } from 'react';

import { Button as ButtonStyled } from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  variant: 'primary' | 'secondary' | 'black';
}

export function Button({ children, variant, ...props }: ButtonProps) {
  return (
    <ButtonStyled variant={variant} {...props}>
      {children}
    </ButtonStyled>
  );
}
