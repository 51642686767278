import { useMediaQuery } from 'react-responsive';

import { Header, Footer } from 'components';

import { SolutionCard } from './components/SolutionCard';

import { Wrapper, Content, Card, Table, TableContent } from './styles';

import mock from './mock';
import techs from './techs';

export const Solutions = () => {
  const tabletQuery = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Wrapper>
      <Header full />
      <Content>
        <h1>Soluções</h1>
        <span>Desenvolvimento de ponta a ponta.</span>
        {!tabletQuery
          ? mock.map((card) => (
              <div key={card.title} id={card.id} className="spaceCardBlock">
                <SolutionCard
                  direction={card.direction as 'left' | 'right'}
                  image={card.image}
                  text={card.text}
                  title={card.title}
                />
              </div>
            ))
          : mock.map((card) => (
              <div key={card.title} id={card.id} className="spaceCardBlock">
                <Card>
                  <h1>{card.title}</h1>
                  <p>{card.text}</p>
                </Card>
              </div>
            ))}
      </Content>
      <TableContent>
        <h1>Trabalhando em conjunto com as melhores tecnologias do mercado:</h1>
        <Table>
          {techs.map((stack) => (
            <div key={stack.title} className="techsBlock">
              <strong>{stack.title}</strong>
              {stack.techs.map((tech) => (
                <span key={tech}>{tech}</span>
              ))}
            </div>
          ))}
        </Table>
      </TableContent>
      <Footer />
    </Wrapper>
  );
};
