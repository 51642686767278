import { useEffect, useState } from 'react';
import { presets } from 'react-text-transition';

import { Text } from './styles';

export interface TextAnimationProps {
  delay?: number;
}

export function TextAnimation({ delay }: TextAnimationProps) {
  const [textIndex, setTextIndex] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const list = ['pessoas', 'profissionais', 'softwares', 'tecnologias'];

  useEffect(() => {
    setTimeout(() => {
      if (textIndex === list.length - 1) {
        return setTextIndex(0);
      }

      setTextIndex((prevState) => prevState + 1);
    }, delay || 5000);
  }, [textIndex, delay, list]);

  return (
    <Text
      text={list[textIndex]}
      springConfig={presets.gentle}
      direction="down"
      noOverflow
      delay={10}
    />
  );
}
