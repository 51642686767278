import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    ${theme.utils.px(theme.space[24])}
    ${theme.utils.py(theme.space[12])}

    display: flex;
    flex-direction: column;

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])};
      ${theme.utils.py(theme.space[4])}
    }

    ${theme.media.mobile} {
      height: 100%;
      ${theme.utils.py(theme.space[0])}
      padding-bottom: ${theme.space[4]};
    }

    > h2 {
      font-size: ${theme.fontSizes['6xl']};

      ${theme.media.tablet} {
        font-size: ${theme.fontSizes['4xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['2xl']};
      }
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.space[12]};

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ${theme.media.mobile} {
      margin-top: ${theme.space[4]};
    }
  `}
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    max-width: 1200px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'card01 card02 card03' '. card04 card05';

    @media (min-width: 468px) {
      > div:nth-child(1) {
        border-right: 0;
      }

      > div:nth-child(2) {
        border-right: 0;
        border-bottom: 0;
      }

      > div:nth-child(3) {
        border-bottom: 0;
      }

      > div:nth-child(4) {
        border-right: 0;
      }
    }

    ${theme.media.mobile} {
      width: 100%;

      display: block;

      > div + div {
        border-top: 0;
      }
    }
  `}
`;
