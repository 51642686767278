import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTransition } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import { List } from 'phosphor-react';

import { MenuContext } from 'context/MenuContext';

import { Menu } from 'components';

import desktopLogo from 'assets/img/header_logo_desk.png';
import mobileLogo from 'assets/img/logo_mobile.png';

import { Header as HeaderStyle } from './styles';

export interface HeaderProps {
  full?: boolean;
}

export function Header({ full }: HeaderProps) {
  const { isMenuOpen, handleChangeMenuStatus } = useContext(MenuContext);
  const mobileQuery = useMediaQuery({ query: '(max-width: 468px)' });

  const transition = useTransition(
    isMenuOpen,
    mobileQuery
      ? {
          from: { x: 2000, opacity: 0 },
          enter: { x: 0, opacity: 1 },
          leave: { x: 2000, opacity: 0 }
        }
      : {
          from: { y: -2000, opacity: 0 },
          enter: { y: 0, opacity: 1 },
          leave: { y: -2000, opacity: 0 }
        }
  );

  return (
    <HeaderStyle full={full}>
      <Link to="/">
        {mobileQuery ? (
          <img src={mobileLogo} alt="Wansoft" />
        ) : (
          <img src={desktopLogo} alt="Wansoft" />
        )}
      </Link>
      <button onClick={() => handleChangeMenuStatus(true)}>
        <List size={40} weight="bold" />
      </button>
      {transition((styles, item) => item && <Menu transition={styles} />)}
    </HeaderStyle>
  );
}
