import {
  LinkedinLogo,
  WhatsappLogo,
  EnvelopeSimple,
  InstagramLogo
} from 'phosphor-react';

export default [
  {
    id: 'linkedin',
    icon: <LinkedinLogo size={32} />,
    href: 'https://www.linkedin.com/company/wansoft-brasil'
  },
  {
    id: 'whatsapp',
    icon: <WhatsappLogo size={32} />,
    href: 'https://api.whatsapp.com/send?phone=5551995886822'
  },
  {
    id: 'email',
    icon: <EnvelopeSimple size={32} />,
    href: 'mailto:comercial@wansoft.com.br'
  },
  {
    id: 'instagram',
    icon: <InstagramLogo size={32} />,
    href: 'https://www.instagram.com/wansoft.brazil/'
  }
];
