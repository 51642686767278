import { ValidationError } from 'yup';

interface ErrorProps {
  [k: string]: string;
}

export function getValidationErrors(error: ValidationError) {
  const errors: ErrorProps = {};

  error.inner.forEach((item) => {
    errors[item.path!] = item.message;
  });

  return errors;
}
