import { InputHTMLAttributes } from 'react';
import { Wrapper } from './styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export function Input({ label, ...props }: InputProps) {
  return (
    <Wrapper>
      <label htmlFor={props.id}>{label}</label>
      <input {...props} />
    </Wrapper>
  );
}
