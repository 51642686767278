import consultIMG from 'assets/img/consultImage.jpg';
import solutionImage from 'assets/img/solutionImage.png';
import supportImage from 'assets/img/supportImage.jpg';

export default [
  {
    id: 'consult',
    image: consultIMG,
    title: 'Consultoria Estratégica',
    direction: 'right',
    text:
      'Estratégia de Tecnologia & Arquitetura de Software. Entendemos a ' +
      'ecessidade do cliente, definimos a estratégia mais adequada e os ' +
      'processos necessários para atender o seu projeto.'
  },
  {
    id: 'customizations',
    image: solutionImage,
    title: 'Soluções Customizadas',
    direction: 'left',
    text:
      'Desenvolvemos softwares de ponta a ponta, sob medida para o seu ' +
      'negócio, com escopo fechado ou trabalho contínuo.'
  },
  {
    id: 'sustenance',
    image: supportImage,
    title: 'Sustentação',
    direction: 'right',
    text:
      'Atuamos com um time especializado prestando o suporte e atendimento ' +
      'necessário para promover melhoria contínua de sistemas já existentes, ' +
      'que forão ou não desenvolvidos por nós.'
  }
];
