import { createContext, useState, useCallback } from 'react';

export interface MenuContextProps {
  isMenuOpen: boolean;
  handleChangeMenuStatus: (status: boolean) => void;
}

export interface MenuProviderProps {
  children: React.ReactNode;
}

export const MenuContext = createContext({} as MenuContextProps);

export function MenuProvider({ children }: MenuProviderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChangeMenuStatus = useCallback((status: boolean) => {
    setIsMenuOpen(status);
  }, []);

  return (
    <MenuContext.Provider value={{ isMenuOpen, handleChangeMenuStatus }}>
      {children}
    </MenuContext.Provider>
  );
}
