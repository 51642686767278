import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;

    display: grid;
    grid-template-columns: 0.15fr 1fr;
    align-items: flex-start;

    ${theme.media.tablet} {
      grid-template-columns: 0.3fr 1fr;
    }

    ${theme.media.mobile} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    label {
      padding: ${theme.space[6]};

      font-size: ${theme.fontSizes.md};
      text-align: center;

      background-color: ${theme.colors.black};
      color: ${theme.colors.white};

      border-radius: ${theme.rounded.md} 0 0 ${theme.rounded.md};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes.sm};
      }

      ${theme.media.mobile} {
        min-width: 7.5rem;
        padding: ${theme.space[4]};

        border-radius: ${theme.rounded.md} ${theme.rounded.md} 0 0;
      }
    }

    textarea {
      height: 300px;
      ${theme.utils.px(theme.space[4])}
      ${theme.utils.py(theme.space[4])}
      flex: 1;

      font-family: ${theme.fonts.default};
      font-size: ${theme.fontSizes.md};
      font-weight: ${theme.fontWeights.regular};

      border: 0;
      border-radius: 0 ${theme.rounded.md} ${theme.rounded.md}
        ${theme.rounded.md};

      color: ${theme.colors.black};

      outline: 0;
      resize: none;

      ${theme.media.mobile} {
        width: 100%;
        min-height: 300px;
        ${theme.utils.px(theme.space[3])}
        ${theme.utils.py(theme.space[4])}

        font-size: ${theme.fontSizes.sm};

        border-radius: 0;
      }
    }
  `}
`;
