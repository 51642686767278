export default [
  {
    title: 'Frontend',
    techs: ['• ReactJS', '• AngularJS', '• VueJS', '• PHP']
  },
  {
    title: 'Backend',
    techs: ['• Java', '• DotNet Core', '• NodeJS', '• PHP']
  },
  {
    title: 'Mobile',
    techs: [
      '• Android Nativo',
      '• IOS Nativo',
      '• React Native',
      '• Ionic',
      '• Xamarim'
    ]
  }
];
