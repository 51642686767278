import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    ${theme.utils.px(theme.space[32])};
    ${theme.utils.py(theme.space[32])};

    background: linear-gradient(#ffffff 90%, #0068ff 10%);

    ${theme.media.tablet} {
      background: linear-gradient(#ffffff 85%, #0068ff 15%);
    }

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])};
      ${theme.utils.py(theme.space[8])};
    }

    > h1 {
      font-size: ${theme.fontSizes['7xl']};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['5xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['3xl']};
      }
    }

    > span {
      ${theme.utils.my(theme.space[6])}

      display: block;

      font-size: ${theme.fontSizes.lg};

      ${theme.media.tablet} {
        font-size: ${theme.fontSizes.md};
      }
    }

    .spaceCardBlock:nth-child(4) {
      display: flex;
      justify-content: flex-end;
    }

    .spaceCardBlock + div {
      margin-top: ${theme.space[64]};

      ${theme.media.tablet} {
        margin-top: ${theme.space[32]};
      }
    }
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    width: 75%;
    padding: ${theme.space[8]};

    border-radius: ${theme.rounded.lg};

    background-color: ${theme.colors.white};

    box-shadow: 0 0 12px ${transparentize(0.7, theme.colors.black)};

    ${theme.media.mobile} {
      width: 100%;
    }

    h1 {
      ${theme.media.tablet} {
        font-size: ${theme.fontSizes['3xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['2xl']};
      }
    }

    p {
      margin-top: ${theme.space[4]};

      line-height: ${theme.lineHeights.md};

      ${theme.media.tablet} {
        font-size: ${theme.fontSizes.sm};
      }
    }
  `}
`;

export const TableContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    ${theme.utils.px(theme.space[32])};
    ${theme.utils.py(theme.space[32])};

    ${theme.media.tablet} {
      ${theme.utils.px(theme.space[8])};
      ${theme.utils.py(theme.space[8])};
    }

    background-color: ${theme.colors.blue};

    h1 {
      font-size: ${theme.fontSizes['7xl']};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['5xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['3xl']};
      }
    }
  `}
`;

export const Table = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.space[16]};
    ${theme.utils.py(theme.space[16])};

    display: flex;
    justify-content: space-around;

    border: ${theme.border[1]} solid ${theme.colors.white};
    border-radius: ${theme.rounded['2xl']};

    ${theme.media.tablet} {
      width: auto;
      flex-direction: column;
      align-items: center;

      div + div {
        margin-top: ${theme.space[8]};
      }
    }

    strong {
      font-size: ${theme.fontSizes['3xl']};

      color: ${theme.colors.white};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['2xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes.xl};
      }
    }

    span {
      display: block;

      font-size: ${theme.fontSizes.xl};
      font-weight: ${theme.fontWeights.light};
      line-height: ${theme.lineHeights.md};

      color: ${theme.colors.white};

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes.sm};
      }
    }

    span:nth-child(2) {
      margin-top: ${theme.space[4]};
    }
  `}
`;
