import { Footer } from 'components';
import {
  HomeSection,
  AboutSection,
  WorkflowSection,
  SolutionSection,
  FormSection
} from './layout';

import { Wrapper } from './styles';

export function Home() {
  return (
    <Wrapper>
      <HomeSection />
      <AboutSection />
      <WorkflowSection />
      <SolutionSection />
      <FormSection />
      <Footer />
    </Wrapper>
  );
}
