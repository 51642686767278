import { Card } from '../../components';

import { Wrapper, Content, Grid } from './styles';

import mock from './mock';

export function WorkflowSection() {
  return (
    <Wrapper id="workSection">
      <h2>Como trabalhamos ?</h2>
      <Content>
        <Grid>
          {mock.map((card) => (
            <Card
              key={card.number}
              number={card.number}
              text={card.text}
              title={card.title}
            />
          ))}
        </Grid>
      </Content>
    </Wrapper>
  );
}
