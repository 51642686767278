import React, { useLayoutEffect } from 'react';
import { Routes, Route, RouteProps, useLocation } from 'react-router-dom';

import { About, CustomerForm, Home, ProfessionalForm, Solutions } from 'pages';

interface PageRoutesProps extends RouteProps {
  pathName: string;
}

const pageRoutes: PageRoutesProps[] = [
  {
    pathName: 'Home',
    path: '/',
    element: <Home />
  },
  {
    pathName: 'About',
    path: '/about',
    element: <About />
  },
  {
    pathName: 'Solutions',
    path: '/solutions',
    element: <Solutions />
  },
  {
    pathName: 'CustomerForm',
    path: '/form/customer',
    element: <CustomerForm />
  },
  {
    pathName: 'ProfessionalForm',
    path: '/form/professional',
    element: <ProfessionalForm />
  }
];

export function RouterPage() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (pathname === '/solutions') return;

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      {pageRoutes.map((route) => (
        <Route key={route.pathName} {...route} />
      ))}
    </Routes>
  );
}
