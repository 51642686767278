import { TextareaHTMLAttributes } from 'react';
import { Wrapper } from './styles';

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
}

export function TextArea({ label, ...props }: TextAreaProps) {
  return (
    <Wrapper>
      <label htmlFor="">{label}</label>
      <textarea {...props} />
    </Wrapper>
  );
}
