import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'phosphor-react';

import { Wrapper, Content, FormLinkBlock } from './styles';

export function FormSection() {
  const tabletQuery = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Wrapper id="formSection">
      <Content>
        <h2>
          Se conecte <br /> com a gente!
        </h2>
        <p>
          Nós acreditamos na inteligência remota e movida por desafios, não
          temos medo do novo, nem barreiras físicas. Somos técnicos, criativos,
          proativos, dispostos a aprender e estamos sempre em busca de projetos
          inovadores e pessoas que queiram somar com a gente. Te identificou?
          Entre em contato. Vocês pode ser uma das inteligências da nossa rede.
        </p>
        <FormLinkBlock>
          <Link to="/form/professional">
            <ArrowRight />
            Formulário para profissionais
          </Link>
          <Link to="/form/customer">
            <ArrowRight />
            Formulário para clientes
          </Link>
        </FormLinkBlock>
      </Content>
      {!tabletQuery && <div className="imageContainer" />}
    </Wrapper>
  );
}
