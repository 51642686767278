export default [
  {
    id: 'quem_somos',
    label: 'Quem somos',
    href: '/about'
  },
  {
    id: 'como_trabalhamos',
    label: 'Como trabalhamos ',
    href: '/#workSection'
  },
  {
    id: 'solucoes',
    label: 'Soluções',
    href: '/solutions'
  },
  {
    id: 'se_conect',
    label: 'Se conecte com a gente',
    href: '/#formSection'
  }
];
