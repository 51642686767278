import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;
    ${theme.utils.px(theme.space[8])};

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    background-color: ${theme.colors.green};

    ${theme.media.tablet} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    ${theme.media.mobile} {
      height: 100%;
      ${theme.utils.py(theme.space[8])};

      display: flex;
      flex-direction: column;
      gap: ${theme.space[8]};
    }

    .textBlock {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      strong {
        width: 650px;
        font-size: ${theme.fontSizes['6xl']};

        color: ${theme.colors.white};

        ${theme.media.laptop} {
          width: 425px;
          font-size: ${theme.fontSizes['4xl']};
        }

        ${theme.media.tablet} {
          font-size: ${theme.fontSizes['2xl']};
          text-align: center;
        }

        ${theme.media.mobile} {
          width: 100%;
          text-align: left;
        }
      }
    }

    .listBlock {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      ${theme.media.mobile} {
        display: block;
      }
    }

    .listContent {
      display: flex;
      flex-direction: column;

      font-size: ${theme.fontSizes['6xl']};
      font-weight: ${theme.fontWeights.bold};

      color: ${theme.colors.blue};

      ${theme.media.laptop} {
        font-size: ${theme.fontSizes['4xl']};
      }

      ${theme.media.mobile} {
        font-size: ${theme.fontSizes['2xl']};
      }
    }
  `}
`;
