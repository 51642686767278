export default [
  {
    number: '01',
    text: 'Entendemos a necessidade da empresa e ajudamos a encontrar as melhores soluções.',
    title: 'Reconhecimento'
  },
  {
    number: '02',
    text: 'Alocação de profissionais especializados (Desenvolvedores e lideranças técnicas) para atender as demandas do projeto.',
    title: 'Definição da squad team'
  },
  {
    number: '03',
    text: 'Definição de data de início do projeto.',
    title: 'Deadline'
  },
  {
    number: '04',
    text: 'Apresentação da estratégia do projeto, objetivos, canais de comunicação e ferramentas de gestão.',
    title: 'Estratégia de desenvolvimento'
  },
  {
    number: '05',
    text: 'Execução do projeto utlizando métodos ágeis.',
    title: 'Implementação & Sustentação'
  }
];
